<script setup>
import S3Image from '../general/S3Image'
import { computed, defineComponent } from 'vue'

const props = defineProps(['category', 'categoryIndex'])
const calcRgbaColor = computed(() => {
  const colors = ['linear-gradient(90deg, #3E5FE7, #4B53BE)', 'linear-gradient(90deg, #4B53BE, #784689)', 'linear-gradient(90deg, #784689, #AE4256)', 'linear-gradient(90deg, #AE4256, #DA4332)']
  return colors[props.categoryIndex]
})
</script>

<template lang="pug">
router-link.categoryContainer(:style="`background: ${$q.screen.lt.md ? calcRgbaColor: ''}; color: ${$q.screen.lt.md ? 'white' : ''}`" :to="'/termekek/'+props.category.url")
  .row.gt-sm(:style="`width: 5vw; min-width: 100px;`" v-if="$q.screen.gt.sm")
    .full-width
      s3-image.flex.justify-start(:src="props.category.img" :img-style="`height: ${$q.screen.width > 1000 ? '120px' : '60px'};`")
  .category-name.self-center(v-if="props.category.name" :style="`font-size: ${$q.screen.gt.sm ? '16px' : '13px'};`" :class="$q.screen.lt.md ? 'text-no-wrap' : ''") {{ props.category.name }}
</template>

<style lang="sass">
.categoryContainer
  cursor: pointer
  background: #ECECEE
  width: 100%
  font-weight: 600
  font-size: 18px
  text-transform: uppercase
  color: #646464
  border-color: white
  transition: all .33s
  padding: 10px 16px
  border-radius: 10px
  margin: 0 4px 0 4px
  @media screen and (max-width: 1023px)
    justify-content: center !important
    display: flex !important
    padding: 10px 30px
    border-radius: 40px !important
    flex-direction: row !important
    text-align: end !important
    line-break: strict !important
  .category-name
    @media screen and (min-width: 1024px)
      padding-right: 10px
    text-align: end
  display: flex
  flex-direction: row
  justify-content: space-between
@media screen and (min-width: 1024px)
  .categoryContainer:hover
    color: #FFFFFF
    background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
</style>
