<script setup>
import { useLayout } from 'src/composables/useLayout'
import { useQuasar } from 'quasar'
const props = defineProps(['review'])
import S3Image from 'components/general/S3Image'
const { translate } = useLayout()
const $q = useQuasar()
</script>

<template lang="pug">
.row.full-width(:style="$q.screen.lt.sm ? 'height: 100%' : 'height: 80%'").cursor-pointer
  .reviewContainer.no-wrap.full-width.shadow-1.full-height.row
    .gt-sm.rating-gradient.absolute-top-right.flex.flex-center(:class="$q.screen.lt.sm ? 'q-pa-md' : 'q-pa-lg'" style="border-radius: 50%; z-index: 1; top: -25px; right: -25px;")
      svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='30' height='30' fill="white")
        path(fill='none' d='M0 0h24v24H0z')
        path(d='M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zm-.692-2H20V5H4v13.385L5.763 17zM11 10h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2z')
    s3-image.flex.flex-center.imgStyle(:class="$q.screen.lt.sm ? 'q-mr-sm' : 'q-mr-md'" :src="props.review.productImg")
    .flex.items-center(style="margin-left: -15px; padding-right: 10px;")
      div
        .row
          .productReviewName {{ props.review.product }}
          q-rating.full-width.review-rating(v-model="props.review.overallRating" readonly color="blue-grey-13" color-selected="blue-grey-14" :size="$q.screen.lt.sm ? '16px' : '24px'")
          .content.full-width.q-pb-sm(:style="`font-size: ${$q.screen.lt.sm ? '13px' : '16px'}`") {{ props.review.comment && props.review.comment.length > ($q.screen.lt.sm ? 30 : 100) ? props.review.comment.substr(0, $q.screen.lt.sm ? 30 : 100) + '...' : props.review.comment }}
        .row.full-width.items-center
          .name.q-pr-xs(:style="`font-size: ${$q.screen.lt.sm ? '12px' : '16px'}`") {{ props.review.fullName ? props.review.fullName : 'Alexandra' }}
          .subtitle(:style="`font-size: ${$q.screen.lt.sm ? '12px' : '16px'}`") ({{ translate('review.registeredUser') }})
</template>

<style scoped lang="sass">
.review-rating
  padding-bottom: 10px
.productReviewName
  padding-bottom: 10px
  @media screen and (min-width: 1023px)
    font-size: 18px
    line-height: 22px
  color: #4E4F53
  font-weight: 600
  width: 100%
  display: flex
  line-height: 1.2
  text-align: left
.reviewContainer
  background: white
  width: 850px
  background-size: 100% 3px
  border-radius: 6px
  color: #4F4F51
  text-align: center
  position: relative
  margin-right: 30px
  margin-left: 30px
  @media screen and (max-width: 600px)
    padding: 16px 10px
  @media screen and (min-width: 601px)
    padding: 30px 50px 30px 30px
  .productName
    text-align: left
    font-size: 17px
    font-weight: 600
    align-self: center
    width: 100%
  .name
    font-weight: 600
    font-size: 16px
    .subtitle
      font-weight: 400
  .content
    text-align: left
.imgStyle
  max-width: 150px
  margin-left: -30px
  @media screen and (max-width: 1024px)
    max-width: 130px !important
    margin-left: -15px
</style>
