<script setup>
import { defineComponent, ref } from 'vue'
import { useLayout } from 'src/composables/useLayout'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { useRouter } from 'vue-router'
import HomeReview from 'components/review/HomeReview'
const props = defineProps(['reviews'])
const { translate } = useLayout()
const reviewSlider = ref(0)
const router = useRouter()
const mouseDrag = ref(false)
const openItem = (review) => {
  if (review.displayAlone) {
    router.push(`/termek/${review.productUrl}`)
  } else if (!review.displayAlone && review.mainProduct && review.mainProduct.url) {
    router.push(`/fo-termek/${review.mainProduct.url}`)
  }
}
const getURL = (review) => {
  if (review.displayAlone) {
    return `/termek/${review.productUrl}`
  } else if (!review.displayAlone && review.mainProduct && review.mainProduct.url) {
    return `/fo-termek/${review.mainProduct.url}`
  }
}
</script>

<template lang="pug">
.reviewsBg.full-width.flex.flex-center.content-center(v-if="reviews")
  .row.gt-sm.items-center.q-pt-lg(style="height: 15% !important;")
    svg.q-mr-sm(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width="30" fill="#e02838" height="30")
      path(fill='none' d='M0 0h24v24H0z')
      path(d='M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zm-.692-2H20V5H4v13.385L5.763 17zM11 10h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2z')
    .promotionTitle {{translate('products.customerReviews')}}
  .contentWidth.relative-position.full-width.no-padding.row.items-center(style="max-width: 100% !important; height: 85% !important;")
    carousel.gt-sm.bg-transparent.row.items-center.review-carousel(ref="categorySlider" v-model="reviewSlider" :wrapAround="true" height="100vh" :autoplay="50000000" :itemsToShow="1.7" :transition="1500")
      template(#slides="{ slidesCount }")
        slide.full-height(@mousedown="mouseDrag = false" @mousemove="mouseDrag = true" @click="!mouseDrag ? openItem(review) : ''" style="padding-right: 30px; padding-left: 30px" v-for="(review, index) in reviews" :key="index")
          router-link(:to="getURL(review)")
            home-review(:review="review")
      template(#addons)
        navigation.gt-sm
    carousel.lt-md.bg-transparent.row.items-center.review-carousel(ref="categorySlider" v-model="reviewSlider" :wrapAround="true" height="80%" :autoplay="50000000" :itemsToShow="1.0" :transition="1500")
      template(#slides="{ slidesCount }")
        slide.full-height(@mousedown="mouseDrag = false" @mousemove="mouseDrag = true" @click="!mouseDrag ? openItem(review) : ''" style="padding-right: 10px; padding-left: 10px" v-for="(review, index) in reviews" :key="index")
          router-link(:to="getURL(review)")
            home-review(:review="review")
      template(#addons)
        navigation.gt-sm
</template>

<style lang="sass">
.review-carousel
  .carousel__viewport, .carousel__track
    height: 100%
  .carousel__next
    right: 35px
  .carousel__prev
    left: 35px
  .carousel__prev, .carousel__next
    .carousel__icon
      color: #59595B
    z-index: 1
    position: absolute
    top: 50%
    width: 35px
    border-radius: 5px
    height: 70px
    background: #DEDEE0
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    &:hover
      background: darkgray
      transition: background-color 0.33s ease-in-out
.reviewsBg
  height: 400px
  @media screen and (max-width: 599px)
    height: 230px
  @media screen and (max-width: 1023px)
    background: linear-gradient(#F3F3F4, #9D9EA0)
  @media screen and (min-width: 1024px)
    background: linear-gradient(#F2F2F2, #BFBEC3)
.review-rating
  .q-rating__icon
    text-shadow: none !important
.rating-gradient
  background: rgb(16,108,235)
  background: -moz-linear-gradient(60deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  background: -webkit-linear-gradient(60deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  background: linear-gradient(60deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
</style>
