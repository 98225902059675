import { reactive, toRefs } from 'vue'
import { server } from 'boot/meteor'
import { useLayout } from 'src/composables/useLayout'

const { locale } = useLayout()

const state = reactive({
  newestProducts: [],
  promotionsHeader: [],
  promotionsSecondary: [],
  promotionThird: null,
  reviews: [],
  blogs: [],
  youtubeVideos: []
})

const loadFirstPromotion = async () => {
  if (!state.promotions || state.promotions.length === 0) {
    const promotions = await server.call('getPromotions', {
      iso: locale.value,
      type: ['HOME_HEADER', 'HOME_SECONDARY', 'HOME_THIRD']
    })
    if (promotions && promotions.length > 0) {
      state.promotionsHeader = []
      state.promotionsSecondary = []
      state.promotionThird = null
      promotions.forEach(promotion => {
        switch (promotion.type) {
          case 'HOME_HEADER': { state.promotionsHeader.push(promotion); break }
          case 'HOME_SECONDARY': { state.promotionsSecondary.push(promotion); break }
          case 'HOME_THIRD': { state.promotionThird = promotion; break }
        }
      })
    }
  }
}

const initHome = async () => {
  try {
    if (!state.newestProducts || state.newestProducts.length === 0) {
      const foundNewestProducts = await server.call('searchAllProducts', {
        iso: locale.value,
        sortBy: 'nrOfPurchases',
        descending: true,
        productLimit: 4,
        mainProductLimit: 4,
        mainProductSkip: 0,
        productSkip: 0,
        newestProduct: true,
        productFields: ['_id', 'discount', 'product_name', 'prices', 'img', 'tasteOrder', 'packagingOrder', 'tasteImg', 'stock', 'packaging', 'unitPrice', 'taste', 'url', 'product_code', 'wishListAdded', 'overallRating', 'ratingCount', 'gifts', 'nrOfAllowedGifts']
      })
      if (foundNewestProducts && foundNewestProducts.items) { state.newestProducts = foundNewestProducts.items }
    }
    if (!state.reviews || state.reviews.length === 0) {
      const res = await server.call('searchReviews', { sortBy: 'commentSize', descending: true, limit: 10, skip: 0, iso: locale.value })
      if (res && res.items) {
        state.reviews = res.items
      }
    }
    if (!state.blogs || state.blogs.length === 0) {
      state.blogs = await server.call('findBlogs', { limit: 4, skip: 0, sortBy: 'order', sortDirection: 1 })
    }
    if (!state.youtubeVideos || state.youtubeVideos.length === 0) {
      state.youtubeVideos = await server.call('getLatestYoutubeVideos')
    }
  } catch (e) {
    console.log(e)
  }
}

export function useHome () {
  return {
    ...toRefs(state),
    initHome,
    loadFirstPromotion
  }
}
