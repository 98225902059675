<script>
import { useLayout } from 'src/composables/useLayout'

export default {
  name: 'SocialLine',
  setup () {
    const { translate } = useLayout()
    const openFb = () => {
      window.open('https://www.facebook.com/puregoldprotein', '_blank').focus()
    }
    const openInsta = () => {
      window.open('https://www.instagram.com/puregoldprotein/', '_blank').focus()
    }
    const openYoutube = () => {
      window.open('https://www.youtube.com/channel/UC5ELQlLQ0ulZikn_jIpLQqg', '_blank').focus()
    }
    return {
      openFb,
      openInsta,
      openYoutube,
      translate
    }
  }
}
</script>

<template lang="pug">
.social.flex.flex-center
  div(style="width: 550px; max-width: 90%")
    .mainLabel {{translate('socialLine.joinOurCommunity')}}
    .social-wrapper
      .row.justify-center.col-lg-4.col-md-4.col-sm-4.col-xs-12.q-px-sm.q-py-xs
        a.socialBtn(@click="openFb" target="_blank" href="https://www.facebook.com/puregoldprotein").blue
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width="24" height="24" fill="white")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z')
          .label Facebook
      .row.justify-center.col-lg-4.col-md-4.col-sm-4.col-xs-12.q-px-sm.q-py-xs
        a.socialBtn(@click="openYoutube" target="_blank" href="https://www.youtube.com/channel/UC5ELQlLQ0ulZikn_jIpLQqg").red
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="white")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z')
          .label Youtube
      .row.justify-center.col-lg-4.col-md-4.col-sm-4.col-xs-12.q-px-sm.q-py-xs
        a.socialBtn(@click="openInsta" target="_blank" href="https://www.instagram.com/puregoldprotein/").purple
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width="24" height="24" fill="white")
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z')
          .label Instagram
</template>

<style lang="sass" scoped>

.socialBtn
  cursor: pointer
  width: 100%
  height: 50px
  border-radius: 10px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px 15px 10px 15px
  @media screen and (max-width: 599px)
    max-width: 200px
    border-radius: 30px !important
  @media screen and (max-width: 1023px)
    border-radius: 5px
    padding: 10px 15px 10px 15px
  .label
    padding-left: 6px
    font-weight: 600
    font-size: 14px
    color: white
    text-transform: uppercase
    font-family: 'Work Sans', sans-serif
    @media screen and (max-width: 1023px)
      font-weight: 600
      font-size: 14px
.social
  background: url('~assets/img/homeSocialBg.webp') no-repeat
  background-size: cover
  background-position: center
  height: 290px
  border-radius: 10px
  @media screen and (max-width: 1023px)
    background: url('~assets/img/homeSocialBg-m.webp') no-repeat
    background-position: bottom center
    background-size: cover
    border-radius: 0
    height: 300px
    .q-btn
      background: #ffffff
      border: 0 !important
.mainLabel
  font-weight: 600
  color: white
  font-size: 24px
  margin-bottom: 24px
  width: 100%
  display: flex
  justify-content: center
  @media screen and (max-width: 599px)
    font-size: 20px !important
    margin-bottom: 20px
  @media screen and (min-width: 600px) and (max-width: 1023px)
    font-size: 22px
    margin-bottom: 20px
.blue
  background-image: -moz-linear-gradient(90deg, #106CEB 0%, #5157B5 100%)
  background-image: -webkit-linear-gradient(90deg, #106CEB 0%, #5157B5 100%)
  background-image: linear-gradient(90deg, #106CEB 0%, #5157B5 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.purple
  background: -moz-linear-gradient(90deg, #5954AE 0%, #A93B68 100%)
  background: -webkit-linear-gradient(90deg, #5954AE 0%, #A93B68 100%)
  background: linear-gradient(90deg, #5954AE 0%, #A93B68 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.red
  background: -moz-linear-gradient(90deg, #B4375F 0%, #F1222A 100%)
  background: -webkit-linear-gradient(90deg, #B4375F 0%, #F1222A 100%)
  background: linear-gradient(90deg, #B4375F 0%, #F1222A 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.blue, .purple, .red
  transition: all .5s
  background-size: 100% auto
.blue:hover, .purple:hover, .red:hover
  background-size: 200% auto
  background-position: left center
  filter: brightness(130%)
.social-wrapper
  display: flex
  flex-direction: row
  @media screen and (min-width: 600px)
    justify-content: center
  @media screen and (max-width: 599px)
    flex-direction: column
    width: 100%
</style>
