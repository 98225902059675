<script setup>
import { computed, defineComponent, ref, watch, onMounted } from 'vue'
import { useHome } from 'src/composables/useHome'
import { useLayout } from 'src/composables/useLayout'
import { useFilters } from 'src/composables/useFilters'
import { throttle, useQuasar, openURL, useMeta } from 'quasar'
import { useRouter } from 'vue-router'
import CategorySlide from 'components/product/CategorySlide'
import ProductListItem from 'components/product/ProductListItem'
import SocialLine from 'components/general/SocialLine'
import HomeReviews from 'components/review/HomeReviews'
import Blog from 'components/blog/Blog'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/dist/style.css'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

const { initHome, loadFirstPromotion, promotionsHeader, promotionsSecondary, promotionThird, reviews, blogs, newestProducts, youtubeVideos } = useHome()
const { translate, categories, headerHeight, featuredProducts } = useLayout()
const { prettifyDate } = useFilters()
const $q = useQuasar()
const router = useRouter()
const secondary = ref(0)
const promotionSlide = ref(0)
const secondaryPromotionSlide = ref(0)
const categorySlideCounter = ref(0)
const secondaryPromotionHeight = ref('30vw')
const mouseDrag = ref(false)
const manuallyMoveSecondary = ref(false)
const mouseDragPromotion = ref(false)
const increment = throttle(function incSlide () {
  manuallyMoveSecondary.value = true
  secondaryPromotionSlide.value += 1
}, 1500)
const decrement = throttle(function decSlide () {
  manuallyMoveSecondary.value = true
  secondaryPromotionSlide.value -= 1
}, 1500)
secondaryPromotionHeight.value = !$q.screen.lt.sm ? '25vw' : '133vw'
const openYtChannel = (link) => {
  openURL(link)
}
// throttle carousel
let timeId
const delayDrag = () => {
  timeId = setTimeout(() => {
    mouseDragPromotion.value = false
  }, 500)
}
const clearDrag = () => {
  clearTimeout(timeId)
  mouseDragPromotion.value = true
}

watch(() => $q.screen.width, val => {
  let availableWidth
  if (val >= 1440) {
    availableWidth = val * 0.9
  } else if (val <= 1000) {
    availableWidth = val * 0.95
  } else {
    availableWidth = val
  }
  if ($q.screen.width < 1000) {
    secondaryPromotionHeight.value = String(availableWidth) + 'px'
  } else {
    secondaryPromotionHeight.value = String(availableWidth * 0.3) + 'px'
  }
}, { immediate: true })

const mappedCategories = computed(() => {
  const newArray = []
  categories.value.forEach(category => {
    if (newArray.length === 0) {
      newArray.push([category])
    } else if (newArray[newArray.length - 1].length > 3) {
      newArray.push([category])
    } else {
      newArray[newArray.length - 1].push(category)
    }
  })
  return newArray
})
const computedFeaturedProducts = computed(() => {
  if ($q.screen.gt.sm || $q.screen.lt.sm) {
    return featuredProducts ? featuredProducts.value : []
  } else {
    return featuredProducts ? featuredProducts.value.slice(0, 3) : []
  }
})
const computedNewestProducts = computed(() => {
  if ($q.screen.gt.sm || $q.screen.lt.sm) {
    return newestProducts ? newestProducts.value : []
  } else {
    return newestProducts ? newestProducts.value.slice(0, 3) : []
  }
})

await loadFirstPromotion()
initHome()

useMeta(() => {
  return {
    title: translate('meta.home.title'),
    meta: {
      description: translate('meta.home.description'),
      canonical: { rel: 'canonical', href: 'https://puregoldprotein.com' }
    }
  }
})

const settings = {
  itemsToShow: 1,
  snapAlign: 'center'
}
const breakpoints = {
  600: {
    itemsToShow: 2,
    snapAlign: 'center'
  },
  1024: {
    itemsToShow: 3,
    snapAlign: 'center'
  },
  1320: {
    itemsToShow: 4,
    snapAlign: 'start'
  }
}
</script>

<template lang="pug">
.full-width.bg-white.row.justify-center(style="min-height: 100vw")
  .full-width
    .full-width(v-if="promotionsHeader" style="position: relative;")
      .gt-sm.absolute-bottom-right(style="z-index: 1; padding-right: 70px; padding-bottom: 35px;" v-if="promotionsHeader && promotionsHeader.length > 0").row
        .cursor-pointer.q-mx-sm(
          v-for="(header, index) in promotionsHeader"
         :key="index"
         @click="promotionSlide = index"
         :style="`width: 18px; height: 3px; border-radius: 15px; background: ${promotionSlide === index ? 'white' : 'grey'}`"
       )
      .lt-md.absolute-bottom.full-width(style="z-index: 1; bottom: 20px ").row.flex.flex-center
        .cursor-pointer.q-mx-xs(
          v-for="(header, index) in promotionsHeader"
         :key="index"
         @click="promotionSlide = index"
         :style="`width: 18px; height: 4px; border-radius: 15px; background: ${promotionSlide === index ? 'white' : 'grey'}`"
       )
      carousel(v-model="promotionSlide" @touchstart="clearDrag" @touchend="delayDrag" @mousedown="clearDrag" @mouseup="delayDrag" :settings="{ pauseAutoplayOnHover: true, itemsToShow: 1, itemsToScroll: 1, transition: mouseDragPromotion ? 200 : 1500, wrapAround: true, snapAlign: 'start', autoplay: 3000 }")
        slide.full-width.q-pa-none.row.items-baseline(v-for="(header, index) in promotionsHeader" :key="header._id" :name="index" style="min-height: 30vw" @click="!mouseDrag ? router.push(header.url) : ''" @mousedown="mouseDrag = false" @mousemove="mouseDrag = true")
          router-link(:to="header.url")
            picture(style='width:100%; object-fit: contain').cursor-pointer
              source(media='(max-width:1023px)' :srcset='`https://assets.puregoldprotein.com/files/${header.mobileImg}`')
              source(media='(min-width:1024px)' :srcset='`https://assets.puregoldprotein.com/files/${header.img}`')
              img(:src='`https://assets.puregoldprotein.com/files/${header.img}`' alt='Banner').full-width
  .contentWidth.secondary-wrapper
    .full-width.flex.flex-center.mainTitleContainer
      svg.q-mr-xs(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill="#e02838").svgSize
        path(fill='none' d='M0 0h24v24H0z')
        path(d='M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26zm0-2.292l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275l-2.038 4.42-4.833.572 3.573 3.305-.949 4.773L12 15.968z')
      .promotionTitle {{translate('home.actualPromotions')}}
    .secondaryCarouselContainer
      .gt-sm.absolute-bottom-right(style="z-index: 1; padding-right: 70px; padding-bottom: 35px;" v-if="promotionsHeader && promotionsHeader.length > 0").row
        .cursor-pointer.q-mx-sm(
          v-for="(secondary, index) in promotionsSecondary"
          :key="index"
          @click="secondaryPromotionSlide = index"
          :style="`width: 18px; height: 3px; border-radius: 15px; background: ${secondaryPromotionSlide === index ? 'white' : 'grey'}`"
        )
      .lt-md.absolute-bottom.full-width(style="z-index: 1; bottom: 20px ").row.flex.flex-center
        .cursor-pointer.q-mx-xs(
          v-for="(secondary, index) in promotionsSecondary"
          :key="index"
          @click="secondaryPromotionSlide = index"
          :style="`width: 18px; height: 4px; border-radius: 15px; background: ${secondaryPromotionSlide === index ? 'white' : 'grey'}`"
        )
      .secondary-carousel-left.gt-sm(@click="decrement" @mouseout="manuallyMoveSecondary = false")
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24')
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z' fill="white")
      .secondary-carousel-right.gt-sm(@click="increment" @mouseout="manuallyMoveSecondary = false")
        svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24')
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill="white")
      carousel.secondary-carousel(ref="secondary" v-model="secondaryPromotionSlide" :settings="{ itemsToShow: 1, itemsToScroll: 1, transition: !manuallyMoveSecondary ? 200 : 1500, wrapAround: true, snapAlign: 'start', autoplay: false }")
        slide(v-for="(secondary, index) in promotionsSecondary" :key="index" @mousedown="mouseDrag = false" @mousemove="mouseDrag = true" @click="!mouseDrag ? router.push(secondary.url) : ''")
          router-link(:to="secondary.url")
            picture.cursor-pointer(style='width:100%; object-fit: contain' @click="!mouseDrag ? router.push(secondary.url) : ''" @mousedown="mouseDrag = false" @mousemove="mouseDrag = true")
              source(media='(max-width:1023px)' :srcset='`https://assets.puregoldprotein.com/files/${secondary.mobileImg}`')
              source(media='(min-width:1024px)' :srcset='`https://assets.puregoldprotein.com/files/${secondary.img}`')
              img(:src='`https://assets.puregoldprotein.com/files/${secondary.img}`' alt='Banner').full-width
  q-scroll-area.categoryScrollArea.lt-md.full-width(:thumb-style="{opacity: 0}" content-style="flex-direction: row; width: max-content !important; display: flex;" content-active-style="flex-direction: row; width: max-content !important; display: flex;")
    template(v-for="(categoryArray, arrayIndex) in mappedCategories")
      template(v-for="(category, categoryIndex) in categoryArray")
        category-slide(:category="category" :category-index="categoryIndex" @mousedown="mouseDrag = false" @mousemove="mouseDrag = true" @click="!mouseDrag ? router.push(`/termekek/${category.url}`) : ''")
  .contentWidth
    .categoriesContainer(v-if="categories").gt-sm
      div(style="z-index: 1; position: absolute; top: calc(50% - 35px); left: 0;")
        .sliderBtn(@click="categorySlideCounter -= 1" style="transform: scale(0.5)")
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24')
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z' fill="#59595B")
      div(style="z-index: 1; position: absolute; top: calc(50% - 35px); right: 0px")
        .sliderBtn(@click="categorySlideCounter += 1" style="transform: scale(0.5)")
          svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24')
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill="#59595B")
      div.relative-position.full-width
        q-no-ssr
          carousel(v-model="categorySlideCounter" :settings='settings' :breakpoints='breakpoints')
            slide(v-for="(category, index) in categories" :key="index")
              category-slide.full-height(:category="category" :index="index" @mousedown="mouseDrag = false" @mousemove="mouseDrag = true" @click="!mouseDrag ? router.push(`/termekek/${category.url}`) : ''")
    .row.justify-center.q-mb-lg
      .full-width.flex.flex-center.mainTitleContainer
        svg.q-mr-xs(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill="#e02838").svgSize
          path(fill='none' d='M0 0H24V24H0z')
          path(d='M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z')
        .promotionTitle {{translate('home.bestSellers')}}
      .row.full-width.product-grid.featured-products
        div(v-for="(product, index) in computedFeaturedProducts")
          product-list-item(:key="product._id" :type="product.searchType" v-model:item="computedFeaturedProducts[index]" :hover="true")
      .full-width.flex.flex-center
        router-link.show-more-link(to="/termekek/osszes")
          .showMoreBtn {{ translate('home.moreProducts') }}

  .featuresWrapper.full-width.flex.flex-center
    .row.contentWidth
      .col-lg-6.col-12.q-py-lg
        .row.items-center.justify-between.full-width.q-pb-md
          .flex.flex-center
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#fff")
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z')
            .pure-gold-tv Pure Gold TV
          q-btn.yt-btn(@click="openYtChannel('https://www.youtube.com/channel/UC5ELQlLQ0ulZikn_jIpLQqg')" flat no-caps)
            svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill="#FFFFFF").q-mr-xs
              path(fill='none' d='M0 0h24v24H0z')
              path(d='M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z')
            .text-white.text-600 YouTube
        LiteYouTubeEmbed(id="Z4J9MLnWOhw" title="Youtube Video")
      .col-lg-6.col-12.youtubeVideosWrapper.q-py-lg
        .pure-gold-tv.q-pb-md További videók
        .row.flex.items-center(v-if="youtubeVideos")
          a.col-lg-4.col-md-4.col-6.q-pa-sm(v-for="(video, index) in youtubeVideos" :href="'https://www.youtube.com/watch?v='+video.id.videoId" target="_blank" :class="index > (!$q.screen.lt.sm ? 6 : 3) ? 'hidden' : ''")
            img(:src='video.snippet.thumbnails.medium.url' :alt='video.snippet.title' style="width:100%")
            .videoTitle(style="color:#ffffff;font-weight:600;font-size:14px") {{video.snippet.title}}
            .videoDate(style="color:#707172;font-weight:600;") {{prettifyDate(video.snippet.publishedAt)}}
    .full-width.flex.justify-center.featuresBg.q-py-lg
      .row.contentWidth
        .col-lg-3.col-md-6.col-sm-6.col-xs-12.featureWrapperContainer
          .featureWrapper.row.items-center
            svg.featureImg.col-4.first-gradient(xmlns='http://www.w3.org/2000/svg' width='50.000000pt' height='50.000000pt' viewBox='0 0 72.000000 72.000000' preserveAspectRatio='xMidYMid meet')
              linearGradient(id="first-gradient")
                stop(offset="0%" stop-color="var(--color-stop-1)")
                stop(offset="100%" stop-color="var(--color-stop-2)")
              g(transform='translate(0.000000,72.000000) scale(0.100000,-0.100000)' stroke='none')
                path(d='M272 705 c-76 -21 -109 -41 -167 -99 -97 -97 -127 -248 -75 -381 31 -80 115 -164 197 -196 80 -31 186 -31 268 1 82 32 163 113 195 195 32 82 32 188 0 270 -62 159 -255 256 -418 210z m203 -52 c70 -23 155 -109 180 -183 42 -123 15 -241 -74 -331 -90 -89 -208 -116 -331 -74 -76 25 -160 109 -185 185 -38 111 -16 232 56 314 93 106 213 136 354 89z')
                path(d='M409 391 l-94 -89 -52 50 c-52 51 -83 59 -83 21 0 -22 109 -133 131 -133 24 0 229 198 229 221 0 38 -38 18 -131 -70z')
            .flex-1.q-pl-md
              .featureTitle {{translate('home.featureTitle1')}}
              .featureBody {{translate('home.featureBody1')}}
        .col-lg-3.col-md-6.col-sm-6.col-xs-12.featureWrapperContainer
          .featureWrapper.row.items-center
            svg.featureImg.col-4.second-gradient(xmlns='http://www.w3.org/2000/svg' width='50.000000pt' height='50.000000pt' viewBox='0 0 72.000000 72.000000' preserveAspectRatio='xMidYMid meet')
              linearGradient(id="second-gradient")
                stop(offset="0%" stop-color="var(--color-stop-1)")
                stop(offset="100%" stop-color="var(--color-stop-2)")
              g(transform='translate(0.000000,72.000000) scale(0.100000,-0.100000)' stroke='none')
                path(d='M269 707 c-104 -30 -190 -104 -237 -206 -22 -46 -27 -71 -27 -141 0\
                -76 3 -92 32 -151 39 -79 102 -140 181 -177 82 -38 202 -38 284 0 79 37 142\
                98 181 177 28 58 32 75 32 151 0 76 -4 93 -32 151 -60 121 -170 196 -300 205\
                -40 2 -87 -2 -114 -9z m216 -56 c62 -28 130 -94 162 -160 36 -73 38 -181 6\
                -253 -30 -66 -95 -133 -162 -165 -75 -37 -180 -38 -258 -4 -62 27 -135 102\
                -166 169 -30 64 -30 178 0 243 73 163 261 239 418 170z')
                path(d='M193 500 c-39 -16 -59 -80 -25 -80 9 0 22 10 27 23 14 31 47 28 63\
                -6 11 -23 16 -26 28 -16 44 37 -34 104 -93 79z')
                path(d='M463 500 c-38 -16 -57 -72 -28 -83 9 -4 19 4 27 21 8 16 21 27 33 27\
                12 0 25 -11 33 -27 14 -30 42 -28 42 2 0 44 -62 79 -107 60z')
                path(d='M273 350 c-26 -11 -43 -40 -43 -74 0 -32 -7 -33 -31 -1 -20 24 -49\
                25 -49 1 0 -17 68 -80 110 -102 47 -25 153 -25 200 0 42 22 110 85 110 102 0\
                27 -29 20 -61 -13 -36 -37 -78 -63 -104 -63 -11 0 -15 12 -15 51 0 43 -5 55\
                -29 80 -30 30 -50 34 -88 19z m59 -47 c12 -11 18 -30 18 -59 0 -50 -4 -54 -47\
                -42 -31 8 -33 11 -33 53 0 31 5 47 18 54 23 14 23 14 44 -6z')
            .flex-1.q-pl-md
              .featureTitle {{translate('home.featureTitle2')}}
              .featureBody {{translate('home.featureBody2')}}
        .col-lg-3.col-md-6.col-sm-6.col-xs-12.featureWrapperContainer
          .featureWrapper.row.items-center
            svg.featureImg.col-4.third-gradient(xmlns='http://www.w3.org/2000/svg' width='50.000000pt' height='50.000000pt' viewBox='0 0 72.000000 72.000000' preserveAspectRatio='xMidYMid meet')
              linearGradient(id="third-gradient")
                stop(offset="0%" stop-color="var(--color-stop-1)")
                stop(offset="100%" stop-color="var(--color-stop-2)")
              g(transform='translate(0.000000,72.000000) scale(0.100000,-0.100000)' stroke='none')
                path(d='M272 705 c-69 -19 -98 -35 -152 -83 -86 -75 -133 -211 -111 -320 36\
                -180 173 -295 351 -295 106 0 175 28 250 103 74 74 103 144 103 250 0 108 -30\
                179 -104 251 -91 90 -222 126 -337 94z m216 -55 c62 -28 136 -101 163 -164 32\
                -71 32 -181 0 -252 -28 -64 -101 -136 -166 -166 -69 -31 -180 -31 -251 1 -64\
                28 -136 101 -166 166 -31 70 -31 180 0 250 40 88 127 161 219 185 53 13 147 4\
                201 -20z')
                path(d='M212 514 c-41 -21 -62 -60 -62 -115 0 -40 5 -47 105 -148 l105 -106\
                105 106 c103 104 105 107 105 153 0 39 -5 52 -34 84 -31 33 -40 37 -84 37 -35\
                0 -56 -6 -71 -19 -20 -18 -22 -18 -40 -2 -28 25 -89 30 -129 10z m110 -49 c16\
                -14 33 -25 38 -25 5 0 22 11 38 25 31 26 70 32 101 15 24 -12 45 -64 37 -92\
                -3 -13 -44 -61 -91 -108 l-85 -85 -85 85 c-91 91 -106 120 -81 168 26 50 81\
                57 128 17z')
            .flex-1.q-pl-md
              .featureTitle {{translate('home.featureTitle3')}}
              .featureBody {{translate('home.featureBody3')}}
        .col-lg-3.col-md-6.col-sm-6.col-xs-12.featureWrapperContainer
          .featureWrapper.row.items-center
            svg.featureImg.col-4.fourth-gradient(xmlns='http://www.w3.org/2000/svg' width='50.000000pt' height='50.000000pt' viewBox='0 0 72.000000 72.000000' preserveAspectRatio='xMidYMid meet')
              linearGradient(id="fourth-gradient")
                stop(offset="0%" stop-color="var(--color-stop-1)")
                stop(offset="100%" stop-color="var(--color-stop-2)")
              g(transform='translate(0.000000,72.000000) scale(0.100000,-0.100000)' stroke='none')
                path(d='M270 704 c-162 -44 -263 -176 -263 -344 0 -105 29 -178 102 -251 166\
                -166 453 -127 563 78 113 209 -1 465 -231 519 -70 17 -102 17 -171 -2z m215\
                -53 c62 -28 130 -94 162 -160 36 -73 38 -181 6 -253 -30 -66 -95 -133 -162\
                -165 -80 -39 -182 -39 -262 0 -67 32 -132 99 -162 165 -29 65 -29 179 0 243\
                73 163 261 239 418 170z')
                path(d='M255 525 c-60 -22 -65 -34 -65 -167 0 -104 2 -120 19 -137 31 -31 86\
                -41 205 -38 108 3 109 4 137 35 53 59 34 148 -37 178 -29 12 -33 19 -36 56 -3\
                40 -6 45 -48 66 -51 25 -115 28 -175 7z m160 -29 c73 -31 24 -86 -75 -86 -64\
                0 -120 22 -120 48 0 44 123 68 195 38z m-75 -116 c48 0 50 -1 38 -20 -24 -38\
                -148 -12 -156 32 -3 16 -1 16 32 3 19 -8 58 -15 86 -15z m172 -11 c29 -15 50\
                -72 39 -103 -21 -54 -88 -72 -134 -36 -33 26 -41 63 -22 100 24 47 71 63 117\
                39z m-175 -59 c18 0 24 -5 21 -17 -3 -14 -12 -17 -43 -14 -48 4 -95 26 -95 45\
                0 12 7 12 47 0 25 -8 57 -14 70 -14z m-27 -58 c50 -4 69 -14 70 -34 0 -14 -92\
                -8 -126 8 -35 17 -50 53 -16 39 9 -4 42 -10 72 -13z')
            .flex-1.q-pl-md
              .featureTitle {{translate('home.featureTitle4')}}
              .featureBody {{translate('home.featureBody4')}}
    .featuresBorderBottom.full-width.gt-sm
  router-link(:to="promotionThird.url")
    picture.cursor-pointer(style='width:100%; object-fit: contain' v-if="promotionThird" @click="!mouseDrag ? router.push(promotionThird.url) : ''" @mousedown="mouseDrag = false" @mousemove="mouseDrag = true")
      source(media='(max-width:1023px)' :srcset='`https://assets.puregoldprotein.com/files/${promotionThird.mobileImg}`')
      source(media='(min-width:1024px)' :srcset='`https://assets.puregoldprotein.com/files/${promotionThird.img}`')
      img(:src='`https://assets.puregoldprotein.com/files/${promotionThird.img}`' alt='Banner').full-width
  .row.lt-md.full-width.flex-center(style="padding: 15px;")
    svg.q-mr-sm(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill="#e02838").svgSize
      path(fill='none' d='M0 0h24v24H0z')
      path(d='M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zm-.692-2H20V5H4v13.385L5.763 17zM11 10h2v2h-2v-2zm-4 0h2v2H7v-2zm8 0h2v2h-2v-2z')
    .promotionTitle {{translate('products.customerReviews')}}
  home-reviews(:reviews="reviews")

  .contentWidth
    .row.justify-center.q-pt-sm.q-mb-lg
      .full-width.flex.flex-center.mainTitleContainer
        svg.q-mr-xs(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill="#e02838").svgSize
          path(fill='none' d='M0 0h24v24H0z')
          path(d='M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm.707 2.122L3.828 12l8.486 8.485 7.778-7.778-1.06-7.425-7.425-1.06zm2.12 6.364a2 2 0 1 1 2.83-2.829 2 2 0 0 1-2.83 2.829z')
        .promotionTitle {{translate('home.newestProducts')}}
      .row.product-grid.newest-products
        div(v-for="(product, index) in computedNewestProducts")
          product-list-item(:key="product._id" :type="product.searchType" v-model:item="computedNewestProducts[index]" :hover="true")
      .full-width.flex.flex-center
        router-link.show-more-link(to="/termekek/osszes")
          .showMoreBtn {{ translate('home.moreProducts') }}

  .contentWidth.q-mt-lg.socialLineContainer
    social-line
  .contentWidth(v-if="blogs")
    .row.justify-center
      .full-width.flex.flex-center.justify-between.q-py-md
        .flex.flex-center
          svg.q-mr-xs(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill="#e02838").svgSize
            path(fill='none' d='M0 0h24v24H0z')
            path(d='M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z')
          .promotionTitle {{translate('blog.blogLiteral')}}
        router-link(to="/blog")
          .showMoreBtn(:style="$q.screen.lt.sm ? 'border:0;padding:0;': ''") {{ translate('blog.moreArticles') }}
    .row.text-center.full-width.product-grid.blogs.q-mb-lg
      div(v-for="(blog, index) in blogs" v-if="blogs" :class="index > (!$q.screen.lt.sm ? 3 : 0) ? 'hidden' : ''")
        blog(:blog="blog" :key="blog._id")
</template>

<style lang="sass">
.blogs
  @media screen and (max-width: 1023px)
    grid-template-columns: repeat(1,1fr)
  @media screen and (min-width: 1024px)
    grid-template-columns: repeat(4,1fr)
.newest-products, .featured-products
  @media screen and (max-width: 1023px)
    grid-template-columns: repeat(2,1fr)
  @media screen and (min-width: 1024px)
    grid-template-columns: repeat(4,1fr)
.product-grid
  display: grid
  @media screen and (max-width: 1023px)
    grid-gap: 0.5rem
  @media screen and (min-width: 1024px)
    grid-gap: 1rem
.featuresWrapper
  background: rgb(126,121,110)
  background: linear-gradient(30deg, rgba(126,121,110,1) 0%, rgba(32,34,37,1) 100%)
.featuresBg
  background: rgb(232,241,253)
  background: linear-gradient(90deg, rgba(232,241,253,1) 0%, rgba(254,235,235,1) 100%)
.featuresBorderBottom
  height: 2px
  background: rgb(130,178,244)
  background: linear-gradient(90deg, rgba(130,178,244,1) 0%, rgba(248,151,155,1) 100%)
.row > label
  border: 0 solid black !important
.sliderBtn
  width: 35px
  border-radius: 5px
  height: 70px
  background: #DEDEE0
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  &:hover
    background: darkgray
    transition: background-color 0.33s ease-in-out
.categoryWidth
  width: 20%
  @media screen and (max-width: 1200px)
    width: 25% !important
  @media screen and (max-width: 600px)
    width: 50% !important
.featureTitle
  color: #4c4f53
  line-height: 2
  @media screen and (max-width: 1023px)
    font-size: 16px
  @media screen and (min-width: 1024px)
    font-size: 18px
  font-weight: 600
.featureBody
  @media screen and (max-width: 1023px)
    font-size: 14px
  @media screen and (min-width: 1024px)
    font-size: 15px
  color: #4E4F54
  font-weight: 400
.promotionTitle
  color: #4d4f53
  font-weight: 600
  @media screen and (min-width: 601px)
    font-size: 30px
  @media screen and (max-width: 600px)
    font-size: 18px
.categoriesContainer
  color: #242424
  font-size: 19px
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  position: relative
  //margin-top: 35px
  margin-top: 20px
  @media screen and (max-width: 1023px)
    margin-top: 15px
    height: 60px !important
.secondary-carousel-left
  left: 15px
.secondary-carousel-right
  right: 15px
.secondary-carousel-left, .secondary-carousel-right
  z-index: 1
  position: absolute
  top: 40%
  width: 35px
  border-radius: 5px
  height: 70px
  background: darkgray
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  transition: background-color 0.33s ease-in-out
  &:hover
    background: #4D4F53
.featureImg
  width: 50px !important
  height: 50px !important
  @media screen and (max-width: 1023px)
    width: 45px !important
    height: 45px !important
.carouselContainer
  min-height: 30vw
  @media screen and (max-width: 1023px)
    min-height: 133vw
.secondary-wrapper
  @media screen and (max-width: 1023px)
    padding: 0 !important
    margin: 0 !important
.mainTitleContainer
  padding: 20px
  @media screen and (max-width: 1023px)
    padding: 15px !important
.svgSize
  width: 30px
  height: 30px
  @media screen and (max-width: 1023px)
    width: 30px !important
    height: 30px !important
.showMoreBtn
  cursor: pointer
  width: 100%
  text-align: center
  color: #4D4F53
  font-weight: 600
  margin: 12px 0
  border-radius: 10px
  font-size: 20px
  padding: 14px 24px 14px 24px
  border: 2px solid #4D4F53
  @media screen and (max-width: 1023px)
    border-radius: 5px !important
    font-size: 16px !important
    padding: 12px !important
.show-more-link
  @media screen and (max-width: 1023px)
    width: 100%
.secondaryCarouselContainer
  width: 100%
  position: relative
  @media screen and (max-width: 1023px)
    padding: 0 !important
.categoryScrollArea
  height: 59px
  margin: 0
  @media screen and (max-width: 1023px)
    margin: 15px 0 0 0
.featureWrapper
  display: flex
  flex-direction: row
  justify-content: space-between
  height: 160px
  padding: 0 10px
  @media screen and (max-width: 1023px)
    background: none !important
    height: auto !important
    border-radius: 0 !important
    padding: 0 !important
  @media screen and (min-width: 1440px)
    height: 100%
.featureWrapperContainer
  @media screen and (max-width: 1023px)
    padding: 8px 8px !important
    margin-top: 8px !important
  @media screen and (min-width: 1440px)
    height: 100%
    &:nth-of-type(1)
      .featureWrapper
        padding-left:0
    &:nth-of-type(4)
      .featureWrapper
        padding-right:0
.socialLineContainer
  @media screen and (max-width: 1023px)
    padding: 0 !important
    margin: 0 !important
    width: 100% !important
#first-gradient
  --color-stop-1: #405AD9
  --color-stop-2: #4855C6
#second-gradient
  --color-stop-1: #584EAD
  --color-stop-2: #6B4997
#third-gradient
  --color-stop-1: #86457C
  --color-stop-2: #9D4265
#fourth-gradient
  --color-stop-1: #B7414E
  --color-stop-2: #CA423E
.first-gradient
  fill: url(#first-gradient) rgb(3, 112, 247)
.second-gradient
  fill: url(#second-gradient) rgb(3, 112, 247)
.third-gradient
  fill: url(#third-gradient) rgb(3, 112, 247)
.fourth-gradient
  fill: url(#fourth-gradient) rgb(3, 112, 247)
.yt-btn
  border-radius: 8px
  background: #DF2938
.pure-gold-tv
  color: #fff
  font-weight: 600
  padding-left: 6px
  font-size: 22px
.youtubeVideosWrapper
  @media screen and (min-width: 1440px)
    padding-left: 50px
.feature-container
  display: grid
  @media screen and (max-width: 599px)
    grid-template-columns: repeat(1,1fr) !important
  grid-template-columns: repeat(2,1fr)
  grid-gap: 1rem
  grid-auto-flow: dense
  flex-direction: row
  color: white
  align-items: center
  @media screen and (min-width: 1024px) and (max-width: 1440px)
    padding-top: 16px
  @media screen and (min-width: 1440px)
    padding-left: 16px
</style>
